body { background-color: var(--clr-1353); }
body { color: #ffffff; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 24px;

}
}
summary {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 20px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-1354);}
a:hover {color: var(--clr-1355);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-1353);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-1353);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-1353);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-1356);
color: #ffffff;
&:hover { color: var(--clr-1353);}
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-1356);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-1356);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-1357);
color: var(--clr-1353);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-1357);
color: #000000;
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-1357);
color: var(--clr-1353);
 }
.MES8 {
background-color: var(--clr-1357);
color: var(--clr-1353);
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: var(--clr-1353);
 }
 }
cite.MEC8{
color: var(--clr-1353);
}
/* Alternate:9 */
.MES9 {
background-color: var(--clr-1357);
color: #000000;
 }
.MES9 {
background-color: var(--clr-1357);
color: #000000;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #000000;
 }
 }
cite.MEC9{
color: #000000;
}
/* Light:10 */
.MES10 {
background-color: var(--clr-1354);
color: #000000;
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-1354);
color: #000000;
 }
.MES11 {
background-color: var(--clr-1354);
color: #000000;
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: #000000;
 }
 }
cite.MEC11{
color: #000000;
}
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-1355);
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-1355);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-1355);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 14.4px;
 }
.MES14 {
font-size: 14.4px;
 }
cite.MEC14{
font-size: 14.4px;
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-1357);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-1354);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-1357);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-1354);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: var(--clr-1353);
text-transform: uppercase;
}
 &:hover > a.MEC17{color: var(--clr-1358);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}




.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-1359);
color: var(--clr-1353);
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: var(--clr-1360);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-1354);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-1353);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 0;}

& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-1354);
color: var(--clr-1353);
border-radius: 50px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-1354);
font-size: 14.4px;
 }
.MES22 {
background-color: var(--clr-1354);
font-size: 14.4px;
 }
cite.MEC22{
font-size: 14.4px;
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

 }
.MES23 {
padding: 10px;

 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-1357);
color: var(--clr-1353);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: var(--clr-1357);
color: var(--clr-1353);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: var(--clr-1353);
 }
 }
cite.MEC24{
color: var(--clr-1353);
}
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-1357) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: var(--clr-1353);
color: var(--clr-1353);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-1354) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: var(--clr-1353);
color: var(--clr-1353);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-1354) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-1356);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 32px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 28px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 20px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 12.8px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 18px; }; }
 }
a.MEC26 { color: var(--clr-1353);
&:hover { color: var(--clr-1356);}
 }
cite.MEC26{
color: var(--clr-1353);
font-size: 18px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Menu Padding:27 */
nav.me-Menu.MES27 {
& .menu-item.MEC27, & .menu-item.MEC27 > div.MEC27{ & > a.MEC27{}
  }
&.horizontal > .menu-item.MEC27 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC27 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC27 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC27 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 30px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* :28 */
.MES28 {
background-color: var(--clr-1361);
 }
.MES28 {
background-color: var(--clr-1361);
 }
/* :29 */
.MES29 {
background-color: var(--clr-1362);
 }
.MES29 {
background-color: var(--clr-1362);
 }
/* :30 */
.MES30 {
background-color: var(--clr-1363);
 }
.MES30 {
background-color: var(--clr-1363);
 }
/* :31 */
.MES31 {
background-color: var(--clr-1363);
 }
